import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NumberSelectorData } from '../number-selector-data';

@Component({
  selector: 'app-number-selector',
  templateUrl: './number-selector.component.html',
  styleUrls: ['./number-selector.component.scss']
})
export class NumberSelectorComponent {

  public size: number;
  public marked: boolean;

  constructor(
    public dialogRef: MatDialogRef<NumberSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NumberSelectorData) {
      this.size = Math.sqrt(data.fields.length);
      this.marked = false;
  }

  public mark(i: number) {
    const pos = this.data.mark.indexOf(i);
    if (pos >= 0) {
      this.data.mark.splice(pos, 1);
    } else {
      this.data.mark.push(i);
    }
  }

  public marking() {
    this.marked = !this.marked;
  }

  public getMarked(int: number) {
    return !(this.data.mark.includes(int));
  }

  public getPossibility(int: number) {
    return !(this.data.pos.includes(int));
  }

}
