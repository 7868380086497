import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  SERVER_URL =
    'https://u7b3t0xozi.execute-api.eu-central-1.amazonaws.com/latest/';
  uploadForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private httpClient: HttpClient
  ) {}

  ngOnInit() {
    this.uploadForm = this.formBuilder.group({
      body: ['']
    });
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get('body').setValue(file);
    }
  }

  onSubmit() {
    const formData = new FormData();
    formData.append('body', this.uploadForm.get('body').value);

    this.httpClient.post<any>(this.SERVER_URL, formData).subscribe(
      res => console.log(res),
      err => console.log(err)
    );
  }
}
