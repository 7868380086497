import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { NumberSelectorComponent } from '../number-selector/number-selector.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription, of } from 'rxjs';

interface SolvedArray {
  index?: number;
  result?: number;
}

interface SolvedField {
  x?: number;
  y?: number;
  index?: number;
}

@Component({
  selector: 'app-playfield',
  templateUrl: './playfield.component.html',
  styleUrls: ['./playfield.component.scss']
})
export class PlayfieldComponent implements OnInit {
  // GameSize
  public size = 9;
  public sqrt: number;
  public sum: number;
  public posarr: number[];

  // Multidimensional Arrays
  public fields: number[][];
  public possibilities: number[][][];
  public markers: number[][][];
  public solved: SolvedField[];

  // File Uploader
  @Input() target = 'https://api.sudomind.domnick.io';
  @Input() text = 'Upload';
  @Input() param = 'file';
  @Input() accept = 'image/*';
  @Output() complete = new EventEmitter<string>();
  private files: FileUploadModel;

  // Function Tasks
  private empty = element => element === undefined;
  private consecutive = (accumulator, currentValue) =>
    // tslint:disable-next-line: semicolon
    accumulator + currentValue;

  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private httpClient: HttpClient,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.sqrt = Math.sqrt(this.size);
    this.fields = new Array(this.size);
    this.possibilities = new Array(this.size);
    this.markers = new Array(this.size);
    this.solved = new Array();
    this.sum = (this.size * (this.size + 1)) / 2;
    this.posarr = Array.from({ length: this.size }, (_, i) => ++i);
  }

  ngOnInit() {
    this.doInit();
    // this.generateSudoku();
    // console.log(this.markers);
  }

  doInit() {
    console.log('Init');
    for (let x = 0; x < this.size; x++) {
      this.fields[x] = new Array(this.size);
      this.possibilities[x] = new Array(this.size);
      this.markers[x] = new Array(this.size);
      for (let y = 0; y < this.size; y++) {
        this.possibilities[x][y] = new Array();
        this.markers[x][y] = new Array();
      }
    }
  }

  openDialog(x: number, y: number): void {
    this.possible(x, y);
    const dialogRef = this.dialog.open(NumberSelectorComponent, {
      width: '250px',
      data: {
        fields: this.fields,
        x,
        y,
        pos: this.possibilities[x][y],
        mark: this.markers[x][y]
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed with ', result);
      if (result === this.fields[x][y]) {
        return;
      }
      if (result === 0) {
        this.fields[x][y] = undefined;
        this.markers[x][y] = [];
      } else if (result !== undefined) {
        if (!this.verifyField(result, x, y, true)) {
          this.fields[x][y] = result;
          this.markers[x][y] = [];
        }
      }
    });
  }

  // #region Verify
  verifyField(result: number, x: number, y: number, notify: boolean) {
    if (this.verifyRow(result, x, y)) {
      if (notify) {
        this.snackBar.open('Row Fail', '', {
          duration: 2000
        });
      }
      return true;
    }
    if (this.verifyColumn(result, x, y)) {
      if (notify) {
        this.snackBar.open('Column Fail', '', {
          duration: 2000
        });
      }
      return true;
    }
    if (this.verifySquare(result, x, y)) {
      if (notify) {
        this.snackBar.open('Square Fail', '', {
          duration: 2000
        });
      }
      return true;
    }
    return false;
  }

  verifyRow(result: number, x: number, y: number): boolean {
    return this.fields[x].includes(result);
  }

  verifyColumn(result: number, x: number, y: number): boolean {
    return this.fields.map(i => i[y]).includes(result);
  }

  verifySquare(result: number, x: number, y: number): boolean {
    const xStart = Math.floor(x / this.sqrt) * this.sqrt;
    const yStart = Math.floor(y / this.sqrt) * this.sqrt;
    for (let xI = 0; xI < this.sqrt; xI++) {
      for (let yI = 0; yI < this.sqrt; yI++) {
        if (this.fields[xI + xStart][yI + yStart] === result) {
          return true;
        }
      }
    }
    return false;
  }
  // #endregion

  // #region Possibilities
  possible(x: number, y: number) {
    // Initialize Possibilities
    let pos = [...this.posarr];
    // Get X and Y Possibilities
    pos = this.possibleArray(pos, this.fields[x]);
    pos = this.possibleArray(
      pos,
      this.fields.map(i => i[y])
    );
    // Get Square Possibilities
    const xStart = Math.floor(x / this.sqrt) * this.sqrt;
    const yStart = Math.floor(y / this.sqrt) * this.sqrt;
    let arr = new Array();
    for (let xI = 0; xI < this.sqrt; xI++) {
      arr = arr.concat(
        this.fields[xStart + xI].slice(yStart, yStart + this.sqrt)
      );
    }
    pos = this.possibleArray(pos, arr);
    // Assign Possibilities
    this.possibilities[x][y] = pos;
  }

  possibleArray(pos: Array<number>, arr: Array<number>): number[] {
    arr.forEach(e => {
      const index = pos.indexOf(e);
      if (index >= 0) {
        pos.splice(index, 1);
      }
    });
    return pos;
  }

  // #endregion Possibilities

  // #region Sole
  soleSudoku(fields: number[][], sqrt: number) {
    for (let x = 0; x < fields.length; x++) {
      this.soleRow(fields, x);
    }
    for (let y = 0; y < fields.length; y++) {
      this.soleColumn(fields, y);
    }
    for (let i = 0; i < sqrt; i++) {
      for (let j = 0; j < sqrt; j++) {
        this.soleSquare(i, j);
      }
    }
  }

  soleArray(arr: Array<number>): SolvedArray {
    const defined = arr.filter(i => i !== undefined);
    if (defined.length === this.size - 1) {
      // If only one is missing
      const index = arr.findIndex(this.empty); // Check where the undefined is
      const result = this.sum - defined.reduce(this.consecutive); // Get the result value
      return { index, result };
    }
    return undefined;
  }

  soleRow(fields: number[][], x: number): boolean {
    const solution = this.soleArray(fields[x]);
    if (solution !== undefined) {
      fields[x][solution.index] = solution.result;
      return true;
    }
    return false;
  }

  soleColumn(fields: number[][], y: number): boolean {
    const solution = this.soleArray(fields.map(i => i[y]));
    if (solution !== undefined) {
      fields[solution.index][y] = solution.result;
      return true;
    }
    return false;
  }

  soleSquare(x: number, y: number): boolean {
    let arr = new Array();
    const xStart = x * this.sqrt;
    const yStart = y * this.sqrt;
    for (let index = xStart; index < xStart + this.sqrt; index++) {
      arr = arr.concat(this.fields[index].slice(yStart, yStart + this.sqrt));
    }
    const solution = this.soleArray(arr);
    if (solution !== undefined) {
      const xFinal = xStart + Math.floor(solution.index / this.sqrt);
      const yFinal = yStart + (solution.index % this.sqrt);
      this.fields[xFinal][yFinal] = solution.result;
      return true;
    }
    return false;
  }
  // #endregion

  // #region Generate
  generateSudoku(pos: number) {
    this.doInit();
    if (isNaN(pos)) {
      pos = 2;
    }
    const i = this.size + Math.floor((Math.random() * this.size) / this.sqrt);
    for (let j = 0; j < i; j++) {
      this.generateNumber();
    }
    if (!this.initSolve()) {
      this.doInit();
      this.generateSudoku(pos);
    }
    for (let x = 0; x < this.fields.length; x++) {
      for (let y = 0; y < this.fields[x].length; y++) {
        this.possible(x, y);
      }
    }
    this.removeStage(pos);
  }

  generateNumber() {
    const n = 1 + Math.floor(Math.random() * this.size);
    const x = Math.floor(Math.random() * this.size);
    const y = Math.floor(Math.random() * this.size);
    if (!this.verifyField(n, x, y, false)) {
      this.fields[x][y] = n;
    } else {
      this.generateNumber();
    }
  }
  // #endregion

  // #region Remove
  removeStage(pos: number) {
    let doing = true;
    while (doing) {
      this.removeField(pos);
      doing = this.removeCheck(pos);
    }
  }

  removeField(pos: number): void {
    const x = Math.floor(Math.random() * this.size);
    const y = Math.floor(Math.random() * this.size);
    if (this.possibilities[x][y].length < pos) {
      this.fields[x][y] = undefined;
      this.possible(x, y);
    } else {
      this.removeField(pos);
    }
  }

  removeCheck(pos: number): boolean {
    for (let x = 0; x < this.fields.length; x++) {
      for (let y = 0; y < this.fields[x].length; y++) {
        if (this.possibilities[x][y].length > pos) {
          return false;
        }
      }
    }
    return true;
  }

  // #endregion

  // #region Solve
  initSolve(): boolean {
    return this.solveAll(0, 0);
  }

  solveAll(x: number, y: number): boolean {
    while (this.size - 1 >= y) {
      while (this.size - 1 >= x) {
        if (this.fields[x][y] === undefined) {
          this.solved.push({
            x,
            y,
            index: 0
          });
          // console.log('Solve: ', x, y, [...this.solved]);
          let res = this.solveXY(this.solved[this.solved.length - 1]);
          while (res === undefined) {
            // No solution for this field
            this.solved.pop(); // Remove current field
            if (this.solved.length === 0) {
              return false;
            }
            const pos = this.solved.length - 1; // Calculate the last object
            this.fields[this.solved[pos].x][this.solved[pos].y] = undefined;
            res = this.solveXY(this.solved[pos]);
          }
          x = this.solved[this.solved.length - 1].x;
          y = this.solved[this.solved.length - 1].y;
        }
        ++x;
      }
      ++y;
      x = 0;
    }
    return true;
  }

  solveXY(solved: SolvedField): SolvedField {
    this.possible(solved.x, solved.y);
    const x = solved.x;
    const y = solved.y;
    const possibility = this.possibilities[x][y];
    if (this.possibilities[x][y].length <= solved.index) {
      return undefined;
    } else {
      this.fields[x][y] = possibility[solved.index];
      solved.index = solved.index + 1;
      return solved;
      // Check if Sudoku is still possible
      // const connected = this.getConnected(x, y);
      // const connected = this.allConnected();
      // if (this.allSolve(connected)) {
      //   ++solved.index;
      //   return solved;
      // } else {
      //   ++solved.index;
      //   return this.solveXY(solved);
      // }
    }
  }

  allConnected(): number[][] {
    const arr = new Array<number[]>();
    for (let x = 0; x < this.fields.length; x++) {
      for (let y = 0; y < this.fields[x].length; y++) {
        if (this.fields[x][y] === undefined) {
          arr.push([x, y]);
        }
      }
    }
    return arr;
  }

  allSolve(arr: number[][]): boolean {
    for (let index = 0; index < arr.length; index++) {
      const x = arr[index][0];
      const y = arr[index][1];
      this.possible(x, y);
      if (this.possibilities[x][y].length === 0) {
        return false;
      } else if (this.possibilities[x][y].length === 1) {
        this.fields[x][y] = this.possibilities[x][y][0];
        return this.allSolve(arr.slice(index, 1));
      }
    }
    return true;
  }

  solveConnected(testing: number[][]): boolean {
    testing.forEach(field => {
      const x = field[0];
      const y = field[1];
      this.possible(x, y);
      if (this.possibilities[x][y].length === 0) {
        // console.log('No more Possibilites');
        return false;
      }
      if (this.possibilities[x][y].length === 1) {
        this.fields[x][y] = this.possibilities[x][y][0];
        const t = this.getConnected(x, y);
        if (!this.solveConnected(t)) {
          return false;
        }
      }
    });
    return true;
  }

  getConnected(x: number, y: number): number[][] {
    const arr = new Array<number[]>();
    for (let yI = 0; yI < this.fields[x].length; yI++) {
      if (this.fields[x][yI] === undefined) {
        arr.push([x, yI]);
      }
    }
    for (let xI = 0; xI < this.fields.length; xI++) {
      if (this.fields[xI][y] === undefined) {
        arr.push([xI, y]);
      }
    }
    const xStart = Math.floor(x / this.sqrt) * this.sqrt;
    const yStart = Math.floor(y / this.sqrt) * this.sqrt;
    for (let xI = 0; xI < this.sqrt; xI++) {
      for (let yI = 0; yI < this.sqrt; yI++) {
        if (this.fields[xStart + xI][yStart + yI] === undefined) {
          arr.push([xStart + xI, yStart + yI]);
        }
      }
    }
    // Remove Duplicates
    let str = new Array<string>();
    arr.forEach((pos, id) => {
      str[id] = JSON.stringify(pos);
    });
    str = [...new Set(str)];
    str.forEach((pos, id) => {
      arr[id] = JSON.parse(pos);
    });
    // Remove Self
    const isSelf = element =>
      JSON.stringify(element) === JSON.stringify([x, y]);
    arr.splice(arr.findIndex(isSelf), 1);
    return arr;
  }

  // #endregion

  // #region FileUpload
  public executeAction(): void {
    this.recaptchaV3Service
      .execute('sudomind')
      .subscribe(token => this.uploadFile(token));
  }

  onClick() {
    const fileUpload = document.getElementById(
      'fileUpload'
    ) as HTMLInputElement;
    fileUpload.onchange = () => {
      this.files = {
        data: fileUpload.files[0],
        state: 'in',
        inProgress: false,
        progress: 0,
        canRetry: false,
        canCancel: true
      };
      this.uploadFiles();
    };
    fileUpload.click();
  }

  private uploadFiles() {
    const fileUpload = document.getElementById(
      'fileUpload'
    ) as HTMLInputElement;
    fileUpload.value = '';
  }

  private uploadFile(token: string) {
    const fd = new FormData();
    fd.append('body', this.files.data);
    fd.append('recaptcha', token);

    let header = new HttpHeaders();
    header = header.set('recaptcha', token);
    console.log(token);

    this.files.inProgress = true;
    this.httpClient
      .post<number[][]>(this.target, fd)
      .subscribe(
        res => this.upload2Array(res),
        err => console.log(err)
      );
  }

  private upload2Array(res: number[][]) {
    for (let x = 0; x < res.length; x++) {
      for (let y = 0; y < res[x].length; y++) {
        if (res[x][y] !== 0) {
          this.fields[y][x] = res[x][y];
        }
      }
    }
  }
}

export class FileUploadModel {
  data: File;
  state: string;
  inProgress: boolean;
  progress: number;
  canRetry: boolean;
  canCancel: boolean;
  sub?: Subscription;
}
